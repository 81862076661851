import LoginPage from "@components/login";
// import PhoneNumberAuthPopup from "../PhoneNumberAuthPopup";

export default function AuthScreen() {
  // return <PhoneNumberAuthPopup open={true} handleClose={() => {}} />;

  return (
    // Redirecting to login component
    <LoginPage />
  );
}
