import styled from "@emotion/styled";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { GET_USER_ORG } from "@operations/user/queries";
import { isValidEmail } from "@utils";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
// import SocialButton from "../SocialButton";
import { apolloQuery } from "@apolloConfig/helpers";
import { PrimaryInput } from "@components/common/CustomInput";
import { InvalidOtp } from "@components/common/Message/messages";
import PhoneInput from "@components/common/PhoneInput/PhoneInput";
import {
  createUser,
  initiateRecaptcha,
  login,
  signInSignUpWithPhoneNumber,
  verifyOtp,
} from "@operations/auth";
import Link from "next/link";
import { useRouter } from "next/router";

const ONBOARD_TYPES = {
  SIGNUP: "signup",
  SIGNIN: "signin",
};

const InputCTA = styled.button`
  border: none;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  background: #4c2e4f;
  margin: 0;
  box-shadow: #4c2e4f;
  cursor: pointer;
  margin-top: 10px;
  line-height: 0px;
`;

const Content = styled(Grid)`
  padding: 32px 40px;
  height: 100%;
  position: static;
  ${(props) => props.theme.breakpoints.down("md")} {
    padding: 12px;
  }
`;

const LoginForm = styled(Grid)`
  position: static;
`;

const LoginContent = styled(Grid)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 54vw;
  min-width: 760px;
  max-width: 850px;
  min-height: 560px;
  background-image: url("/assets/images/naturethings_logo.svg");
  background-size: 30%;
  background-repeat: no-repeat;
  border-radius: 12px;
  overflow: auto;
  border: 1px solid #ede0e0;
  box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
  ${(props) => props.theme.breakpoints.down("md")} {
    min-width: 90vw;
    width: 50vw;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
  }
`;

const CustomTextField = styled(TextField)`
  width: 329px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: column;
  border-radius: 20px;
  height: 45px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  border: 1px solid #4c2e4f;
  box-shadow: 0 0 0px 2px #4c2e4f;
  background: transparent;
  .css-nago0e-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 20px;
  }
  .css-ovdzzh-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 20px;
  }
  & input:-webkit-autofill,
  & input:-webkit-autofill:hover,
  & input:-webkit-autofill:focus,
  & input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
`;

const LoginPage = ({ authDialog, additionalElement = null }) => {
  const { t } = useTranslation(["common", "discover"]);
  const [type] = useState(ONBOARD_TYPES.SIGNIN);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const captchaRef = useRef();
  const [gotOtp, setGotOtp] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({
    email: false,
    password: false,
    subject: false,
    message: false,
  });
  const [showPassword, setShowPassword] = useState(false);

  const router = useRouter();

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e);
  };

  const isSignIn = ONBOARD_TYPES.SIGNIN === type;

  useEffect(() => {
    if (!authDialog) {
      setGotOtp(false);
      setOtp("");
    }
  }, [authDialog]);

  const handlePhoneAuth = async () => {
    await initiateRecaptcha(captchaRef.current);
    await signInSignUpWithPhoneNumber({
      phoneNumber: phoneNumber,
    });

    setGotOtp(true);
  };

  const submitOtp = async () => {
    try {
      const result = await verifyOtp(otp);
      await createUser(result.user.accessToken);
    } catch (e) {
      InvalidOtp();
      setOtp("");
    }
  };

  const handleSignupSignin = async () => {
    const user = await login({ email, password });
    if (user) {
      try {
        const { data: { userOrg: orgData } = {} } = await apolloQuery(
          GET_USER_ORG,
          {
            fetchPolicy: "network-only",
          }
        );
        router.push(`/org/dashboard?spaceId=${orgData?.spaceId}`);
      } catch (error) {
        console.error("Error fetching user organization data:", error);
      }
    }
  };

  return (
    <LoginContent>
      <Content container direction={"row"} justifyContent={"center"}>
        <Typography color="common.black" variant="h3">
          {isSignIn ? t("sign_in") : t("create_account")}
        </Typography>
        <LoginForm
          container
          xs={12}
          sx={{
            mt: isSignIn ? 4 : { xs: 4, md: 2 },
          }}
          px={{ sm: 5, md: 0 }}
        >
          <Grid item sm={12} xs={12} pt={{ xs: 1, sm: 2 }}>
            <Typography
              sx={{
                mt: !additionalElement ? (isSignIn ? 4 : { xs: 4, md: 2 }) : 0,
              }}
              fontWeight="bold"
              align="left"
              fontFamily="Inter"
            >
              {gotOtp ? t("enter_otp") : t("enter_phone_no")}
            </Typography>
          </Grid>

          <div ref={captchaRef} id="recaptcha-container"></div>

          {gotOtp ? (
            <PrimaryInput
              showCTA
              inputProps={{
                maxLength: 6,
                type: "number",
                onWheel: (e) => {
                  e.target.blur();
                },
              }}
              onClick={submitOtp}
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
          ) : (
            <PhoneInput
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              onClick={handlePhoneAuth}
            />
          )}
          <Grid item sm={12} xs={12} pt={{ xs: 1, sm: 1.5 }}>
            <Typography
              sx={{
                mt: !additionalElement ? (isSignIn ? 1 : { xs: 4, md: 2 }) : 0,
              }}
              fontWeight="bold"
              align="left"
              fontFamily="Inter"
            >
              {t("OR")}
            </Typography>
          </Grid>
          <Grid item sm={12} xs={12} pt={{ xs: 1, sm: 0.4 }}>
            <Typography
              sx={{
                mt: !additionalElement ? (isSignIn ? 1 : { xs: 4, md: 2 }) : 0,
              }}
              fontWeight="bold"
              align="left"
              fontFamily="Inter"
            >
              {t("email_address")}
            </Typography>
          </Grid>
          <CustomTextField
            fullWidth
            size="small"
            className="email-input"
            value={email}
            onBlur={() => {
              if (!isValidEmail(email)) {
                setError({ ...error, email: true });
              }
            }}
            onChange={(event) => {
              setEmail(event.target.value);
              setError({
                ...error,
                email: false,
              });
            }}
            inputProps={{
              name: "email",
            }}
            placeholder={t("email_placeholder")}
            error={error.email && Boolean(error.email)}
            helperText={error.email && t("email_error")}
          />
          <Grid item sm={12} xs={12} pt={{ xs: 1, sm: 0.4 }}>
            <Typography
              sx={{
                mt: !additionalElement ? (isSignIn ? 1 : { xs: 4, md: 2 }) : 0,
              }}
              fontWeight="bold"
              align="left"
              fontFamily="Inter"
            >
              {t("password")}
            </Typography>
          </Grid>
          <CustomTextField
            fullWidth
            size="small"
            placeholder={t("password")}
            type={showPassword ? "text" : "password"}
            onChange={(event) => {
              setPassword(event.target.value);
              setError({
                ...error,
                password: false,
              });
            }}
            inputProps={{
              name: "password",
            }}
            error={error.password && Boolean(error.password)}
            helperText={error.password && t("password_error")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Grid item sm={12} xs={12} pt={{ xs: 1, sm: 0 }}>
            <InputCTA onClick={handleSignupSignin}>
              <Image
                width={30}
                height={20}
                src="/assets/images/coolicon.svg"
                alt=""
              />
            </InputCTA>
          </Grid>

          <Grid>
            <Grid pt={3}>
              <Typography
                variant="body2"
                color="text.primary"
                fontFamily="Inter"
                align="center"
              >
                {t("accept_terms_statement1")}
                <Link href="/terms_of_use" passhref>
                  {t("terms_of_use")}
                </Link>
                {t("and")}
                <Link href="/privacy_policy" passhref>
                  {t("privacy_policy")}
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </LoginForm>
      </Content>
    </LoginContent>
  );
};

LoginPage.propTypes = {
  authDialog: PropTypes.bool,
  additionalElement: PropTypes.element,
};

export default LoginPage;
