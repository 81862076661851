import styled from '@emotion/styled';
import MuiPhoneNumber from 'material-ui-phone-number';
import Image from 'next/image';
import PropTypes from 'prop-types';
import React from 'react';

const StyledInput = styled(MuiPhoneNumber)`
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  border: none;
  margin: 8px 8px;
  padding: 0px 12px;
  outline: none;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 20px;
  height: 45px;
  justify-content: space-between;
  border: ${(props) =>
    props.color === 'primary' ? '1px solid #4c2e4f' : '1px solid #D1FE4E'};
  box-shadow: ${(props) =>
    props.color === 'primary' ? '0 0 0px 2px #4c2e4f' : '0 0 0px 2px #D1FE4E'};
  background: ${(props) =>
    props.color === 'primary' ? 'transparent' : '#fff'};
`;

const InputCTA = styled.button`
  border: none;
  border-radius: 20px;
  width: 50px;
  background: ${(props) => (props.color === 'primary' ? '#4c2e4f' : '#D1FE4E')};
  margin: 0;
  box-shadow: ${(props) =>
    props.color === 'primary' ? '0 0 0px 3px #4c2e4f' : '0 0 0px 3px #D1FE4E'};
  cursor: pointer;
`;

export default function PhoneInput(props) {
  const icon =
    props.icon || props.color === 'primary'
      ? '/assets/images/coolicon.svg'
      : '/assets/images/purple_coolicon.svg';
  return (
    <InputContainer color={props.color}>
      <StyledInput
        defaultCountry="sg"
        countryCodeEditable={true}
        aria-describedby="whatsapp_cta"
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
        InputProps={{
          disableUnderline: true,
        }}
      />
      <InputCTA
        disabled={props.disabled}
        sx={{
          opacity: props.disabled ? 0.5 : 1,
        }}
        onClick={props.onClick}
        color={props.color}
      >
        <Image width={30} height={20} src={icon} alt="" />
      </InputCTA>
    </InputContainer>
  );
}

PhoneInput.defaultProps = {
  placeholder: 'Enter your phone number',
  icon: null,
  inputProps: {},
  color: 'primary',
};

PhoneInput.propTypes = {
  placeholder: PropTypes.string,
  icon: PropTypes.string,
  inputProps: PropTypes.object,
  color: PropTypes.oneOf(['primary', 'secondary']),
};
