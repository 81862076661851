import { onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { auth } from "../../../../firebase";
import { Unauthenticated } from "../Message/messages";
import nookies from "nookies";
import { getQueryParams } from "../../../utils";
import { apolloQuery } from "@apolloConfig/helpers";
import { GET_USER_ORG } from "@operations/user/queries";

const withAuth = (Component) => {
  // eslint-disable-next-line react/display-name
  return (props) => {
    const [loggedIn, setLoggedIn] = useState(false);
    const router = useRouter();

    const unauthRoutes = ["/login", "/signup"];

    const handleAuthenticatedRedirect = async () => {
      if (unauthRoutes.includes(router.pathname)) {
        const { data: { userOrg: orgData } = {} } = await apolloQuery(
          GET_USER_ORG,
          {
            fetchPolicy: "network-only",
          }
        );
        const params = getQueryParams();
        let path = `/org/dashboard?spaceId=${orgData?.spaceId}`; // Default redirect after auth
        if (params.redirect) {
          path = params.redirect;
        }
        router.push(path);
      }
    };

    useEffect(() => {
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          const token = user.accessToken;
          nookies.set(undefined, "token", token);
          await handleAuthenticatedRedirect();
          setLoggedIn(true);
        } else {
          Unauthenticated();
          nookies.set(undefined, "token", "");
          if (!unauthRoutes.includes(router.pathname)) {
            router.push(`/login?redirect=${router.pathname}`);
          } else {
            setLoggedIn(true);
          }
        }
      });
      // eslint-disable-next-line
    }, []);

    return loggedIn ? <Component {...props} /> : <></>;
  };
};

export default withAuth;
