import styled from '@emotion/styled';
import { Box, TextField } from '@mui/material';
import Image from 'next/image';
import PropTypes from 'prop-types';
import React from 'react';
import { colors } from '../../../theme/colors';

export const StyledTextField = styled(TextField)`
  fieldset {
    border-top-left-radius: 14rem 29rem;
    border-top-right-radius: 20rem;
    border-bottom-right-radius: 15rem;
    border-bottom-left-radius: 19rem 15rem;
  }
  font-size: 16px;
  font-weight: 600;
  border: none;
  margin-bottom: 1rem;
`;

const StyledInput = styled.input`
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  border: none;
  margin: 8px 8px;
  padding: 0px 12px;
  outline: none;
  background: transparent;
  color: ${(props) =>
    props.inputColor
      ? props.inputColor
      : props.color === 'secondary'
        ? colors.primary.green
        : colors.primary.purple};
`;

const InputContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  border-radius: 30px;
  height: 45px;
  justify-content: space-between;
  margin-bottom: 1rem;
  border: 1px solid
    ${(props) =>
      props.color === 'secondary'
        ? colors.primary.green
        : colors.primary.purple};
  box-shadow: 0 0 0px 2px
    ${(props) =>
      props.color === 'secondary'
        ? colors.primary.green
        : colors.primary.purple};
  border-radius: 20px;
`;

const InputCTA = styled.button`
  border: none;
  width: 50px;
  background: ${(props) =>
    props.color === 'secondary' ? colors.primary.green : colors.primary.purple};
  margin: 0;
  border-radius: 20px;
  box-shadow: 0 0 0px 3px
    ${(props) =>
      props.color === 'secondary'
        ? colors.primary.green
        : colors.primary.purple};
  cursor: pointer;
`;

export function PrimaryInput({
  icon,
  inputProps,
  showCTA,
  containerProps,
  className,
  color,
  style,
  inputColor,
  placeholder,
  value,
  onChange,
  onClick,
  disabled,
  fullWidth,
  name,
  ...props
}) {
  return showCTA ? (
    <InputContainer
      className={className}
      color={color}
      style={style}
      {...containerProps}
    >
      <StyledInput
        {...inputProps}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        inputColor={inputColor}
        name={name}
        {...props}
      />
      <InputCTA disabled={disabled} onClick={onClick} color={color}>
        {icon}
      </InputCTA>
    </InputContainer>
  ) : (
    <StyledTextField
      {...inputProps}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      inputColor={inputColor}
      name={name}
      fullWidth={fullWidth}
      {...props}
    />
  );
}

PrimaryInput.defaultProps = {
  icon: (
    <Image width={30} height={20} src="/assets/images/coolicon.svg" alt="" />
  ),
  inputProps: {},
  showCTA: true,
  containerProps: {},
  className: '',
  color: 'primary',
  style: {},
  inputColor: '',
  placeholder: '',
  value: '',
  onChange: () => {},
  onClick: () => {},
  disabled: false,
  fullWidth: false,
  name: '',
};

PrimaryInput.propTypes = {
  icon: PropTypes.element,
  inputProps: PropTypes.object,
  showCTA: PropTypes.bool,
  containerProps: PropTypes.object,
  className: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.object,
  inputColor: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  name: PropTypes.string,
};
